@use "node_modules/@angular/material" as mat;

@mixin bewa-news-bar($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  bewa-news-bar {
    &.mat-primary .news-bar-content {
      color: mat.get-theme-color($theme, "primary");
      // background-color: mat-color($primary, A400);
    }

    &.mat-accent .news-bar-content {
      color: mat.get-theme-color($theme, "accent");
      // background-color: mat-color($accent, A400);
    }

    &.mat-warn .news-bar-content {
      color: mat.get-theme-color($theme, "warn");
      // background-color: mat-color($warn, A400);
    }
  }
}
