// mixin name will be used in main style.scss
@mixin bewa-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  .mat-drawer-container {
    background-color: rgba($color: #000000, $alpha: 0);
  }

  .mat-tooltip {
    font-size: 12px;
  }
}
