// mixin name will be used in main style.scss
@mixin login-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $heading: map-get($theme, heading);

  $background-color: mat.get-theme-color(
    $theme,
    "background",
    "dialog"
  ) !default;

  // all of these variables contain many additional variables

  .new-notification {
    color: mat.get-theme-color($theme, "warn");
  }

  .pin-icon {
    fill: rgba(0, 0, 0, 0.54);
  }
}
