@use "@angular/material" as mat;
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

// mixin name will be used in main style.scss
@mixin bewa-config-templates-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  .tree-node:hover {
    background: rgba(#000000, 0.2);
  }

  .tree-node.active {
    background: mat.m2-get-color-from-palette($primary);
    color: white;
  }
}
