@use "@angular/material" as mat;

// mixin name will be used in main style.scss
@mixin breadcrumb-component-theme($theme) {
  // retrieve variables from theme
  $primary: mat.get-theme-color($theme, "primary");
  $accent: mat.get-theme-color($theme, "accent");
  $warn: mat.get-theme-color($theme, "warn");
  $foreground: mat.get-theme-color($theme, "foreground");
  $background: mat.get-theme-color($theme, "background");

  $background-color: mat.get-theme-color(
    $theme,
    "background",
    "background"
  ) !default;

  app-breadcrumb {
    .breadcrumb li + li:before {
      content: "";
      color: mat.get-theme-color($theme, "primary");
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0;
    }

    .breadcrumb {
      height: 100%;
      background-color: $background-color;
      padding: 6px;
      align-items: center;
      top: 0;
      border-radius: 0;
      margin: 0px;

      .breadcrumb-link {
        padding-right: 0.5rem;
      }

      a:hover {
        color: mat.get-theme-color($theme, "accent");
      }
      a {
        color: mat.get-theme-color($theme, "primary"); //#9E9E9E;
      }
    }
  }
}
