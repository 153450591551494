@use "@angular/material" as mat;

// mixin name will be used in main style.scss
@mixin patient-profiles-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  bewa-patientprofiles {
    .dragged {
      background-color: mat.m2-get-color-from-palette($accent);
      opacity: 0.4;
    }

    .add-profile-button {
      background-color: rgba(black, 0.12);
    }
    .add-profile-button:hover {
      background-color: rgba(black, 0.24);
    }
  }

  .mat-autocomplete-panel mat-option.mat-option {
    // margin: 1rem 0;
    overflow: visible;
    line-height: initial;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  .mat-option-text.mat-option-text {
    white-space: normal;
  }
}
