@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

// mixin name will be used in main style.scss
@mixin bewa-configuration-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // all of these variables contain many additional variables

  @include media-breakpoint-down(md) {
    .mat-form-field-infix {
      border-top: none;
    }
  }

  mat-option.mat-option {
    // margin: 1rem 0;
    overflow: visible;
    line-height: initial;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  .mat-option-text.mat-option-text {
    white-space: normal;
  }
}
