@use "@angular/material" as mat;
@use "node_modules/@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss"
  as datetimepicker;
@use "app/external-modules/notifications/themes.scss" as notifications;
@use "app/external-modules/header/themes.scss" as header;

@import "bootstrap/scss/bootstrap.scss";
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include mat.core();

@import "app/app.component.theme";
@import "app/modules/patient-profiles/patient-profiles.component.theme";
@import "app/modules/configuration/configuration.component.theme";
@import "app/modules/administration/components/config-templates/config-templates.component.theme";
@import "app/modules/location-groups/location-groups.component.theme";

$light-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 700, 300, 900);
$light-accent: mat.m2-define-palette(mat.$m2-light-blue-palette);
$light-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200);
$light-heading: mat.m2-define-palette(mat.$m2-grey-palette, 600);

$dark-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$dark-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-red-palette);
$dark-heading: mat.m2-define-palette(mat.$m2-grey-palette, 600);

$typography: mat.m2-define-typography-config(
  $font-family: "Calibri, liberation-sans, Sans-Serif",
  $headline-1: mat.m2-define-typography-level(32px, 48px, 700),
  $headline-2: mat.m2-define-typography-level(32px, 48px, 700),
  $headline-3: mat.m2-define-typography-level(32px, 48px, 700),
  $headline-4: mat.m2-define-typography-level(32px, 48px, 700),
  $headline-5: mat.m2-define-typography-level(32px, 48px, 700),
  $headline-6: mat.m2-define-typography-level(32px, 48px, 700),
  $subtitle-1: mat.m2-define-typography-level(14px, 24px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 400),
  $body-1: mat.m2-define-typography-level(14px, 24px, 400),
  $body-2: mat.m2-define-typography-level(16px, 24px, 400),
  $caption: mat.m2-define-typography-level(14px, 24px, 400),
  $button: mat.m2-define-typography-level(15px, 24px, 400),
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
      foreground: mat.$m2-light-theme-foreground-palette,
      background: mat.$m2-light-theme-background-palette,
      heading: $light-heading,
    ),
    typography: $typography,
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
      foreground: mat.$m2-dark-theme-foreground-palette,
      background: mat.$m2-dark-theme-background-palette,
      heading: $dark-heading,
    ),
    typography: $typography,
  )
);

@mixin custom-components-theme($theme) {
  @include patient-profiles-component-theme($theme);
  @include bewa-component-theme($theme);
  @include bewa-configuration-component-theme($theme);
  @include bewa-config-templates-component-theme($theme);
  @include bewa-location-groups-theme($theme);
  @include mat.form-field-density(-2);
  @include mat.icon-button-density(-2);
}

@include mat.all-component-typographies($typography);

html {
  @include mat.all-component-themes($light-theme);
  @include header.header-module-theme($light-theme);
  @include notifications.notification-module-theme($light-theme);
  @include custom-components-theme($light-theme);
  @include datetimepicker.mat-datetimepicker-theme($light-theme);
}

html.dark {
  @include mat.all-component-colors($dark-theme);
  @include header.header-module-theme($dark-theme);
  @include notifications.notification-module-theme($dark-theme);
  @include custom-components-theme($dark-theme);
  @include datetimepicker.mat-datetimepicker-theme($dark-theme);
}

html.dark body {
  background-color: rgb(63, 63, 63) !important;
}
