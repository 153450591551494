// @tailwind base;
@tailwind components;
@tailwind utilities;

@import "theme";

.mdc-list-item {
  padding-left: 16px;
  padding-right: 16px;
}

.breadcrumb {
  background-color: transparent !important;
  padding: 0 !important;
}

.full-width.cdk-overlay-pane {
  max-width: 95% !important;
  .mat-dialog-container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
    .mat-dialog-actions {
      button {
        margin: 0;
      }
    }
  }
}

.module-header {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  font-weight: bold;

  .headline {
    display: flex;
    align-items: center;
  }

  .module-sub-header {
    display: block;
    font-size: 0.5em;
    margin-top: 0.67em;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    font-weight: lighter;
  }

  .header-line {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 0.5rem;
    }
  }
}

.custom-drop-zone {
  margin: 5px;
  height: 100px;
  border: 2px solid lightgray;
  border-radius: 0;

  .upload-description {
    color: grey;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;

    .error-description {
      color: rgba(red, 1);
    }
  }

  :hover {
    cursor: pointer;
    background-color: lightgray;
  }

  .over {
    background-color: rgba(147, 147, 147, 0.5);
  }
}

.mat-tab-group.mat-tab-fill-height {
  height: 100%;
  .mat-tab-body-wrapper {
    flex: 1 1 100%;
  }
}

.configuration-panel-loaded {
  .mat-expansion-panel {
    pointer-events: all;
  }
}

.configuration-panel-partly-loaded {
  .mat-expansion-panel {
    pointer-events: none;
    opacity: 0.8;
  }
}

input:disabled,
textarea:disabled,
select:disabled {
  background-color: #f5f5f5; // Light gray background
  opacity: 0.6; // Reduce opacity
  cursor: not-allowed; // Change cursor to indicate non-editable state
  color: #9e9e9e; // Gray text color
}
